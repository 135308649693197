












































































import {Vue, Component} from 'vue-property-decorator';
import {Validate} from "vuelidate-property-decorators";
import {email, required, sameAs} from "vuelidate/lib/validators";
import * as actionTypes from "@/store/action-types";
import {AgentsService} from "@/http/services/agents.service";
import {NotificationType} from "@/interfaces/models/INotification";

@Component
export default class RecoveryForm extends Vue {
  email: string = 'email@example.com';
  password: string = '';
  @Validate({sameAs: sameAs('password')})
  repeatPassword: string = '';

  isLoading: boolean = false;
  isShowPassword: boolean = false;
  isShowRepeatPassword: boolean = false;

  created(): void {
    this.email = this.$route.query.email as string;
  }

  get passwordFieldIcon(): string {
    return this.isShowPassword ? 'mdi-eye-outline' : 'mdi-eye-off-outline';
  }

  get passwordFieldType(): string {
    return this.isShowPassword ? 'text' : 'password';
  }

  get repeatPasswordFieldIcon(): string {
    return this.isShowRepeatPassword ? 'mdi-eye-outline' : 'mdi-eye-off-outline';
  }

  get repeatPasswordFieldType(): string {
    return this.isShowRepeatPassword ? 'text' : 'password';
  }

  toggleShowPassword(): void {
    this.isShowPassword = !this.isShowPassword;
  }

  toggleShowRepeatPassword(): void {
    this.isShowRepeatPassword = !this.isShowRepeatPassword;
  }

  async updatePassword(): Promise<void> {
    this.$v.$touch();
    if (this.$v.$invalid) {
      return;
    }

    try {
      this.isLoading = true;
      const agentAccountId = +(this.$route.query.agentAccountId as string);
      await AgentsService.resetPassword(agentAccountId, this.password, this.repeatPassword);
      await this.$router.push('/sign-in')
    } catch (e) {
       await this.$store.dispatch(actionTypes.SHOW_NOTIFICATION, {
        text: e.message,
        type: NotificationType.ERROR
      });
    } finally {
      this.isLoading = false;
    }
  }

  get repeatPasswordError(): string | null {
    if (!this.$v.repeatPassword.$invalid || !this.$v.repeatPassword.$dirty) {
      return null;
    }

    return 'Passwords should be the same';
  }
}

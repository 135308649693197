











































































































































































































































































































































































import { Component, Vue } from 'vue-property-decorator';
import DemoPopup from "@/components/demo/DemoPopup.vue";
import { IStripeProduct } from "@/interfaces/models/IStripeProduct";
import { IStripePrice, StripeCurrency } from "@/interfaces/models/IStripePrice";
import { ProductService } from "@/http/services/product.service";
import { Getter } from "vuex-class";
import * as actionTypes from '@/store/action-types';

@Component({
  components: {DemoPopup}
})
export default class SelectPlan extends Vue {
  @Getter stripeProducts!: IStripeProduct[];

  indexURL: string = location.href.includes('beta')
    ? 'https://textodog.com'
    : 'https://textodog.com/';

  isUSD: boolean = true;
  countOfUsers: number = 3;
  isShowDemoPopup: boolean = false;
  messagesCount = 50500;

  freeProductPrices: IStripePrice[] = [];
  fullProductPrices: IStripePrice[] = [];
  biggerContainerHeight: number = 0;
  isVisible = false;
  pricesLoaded: boolean = false;
  isPlatformMode = true;
  currentYear: number = new Date().getFullYear();
  isMenuOpen: boolean = false;
  phoneNumber: string = '';

  toggleMenu(): void {
    this.isMenuOpen = !this.isMenuOpen;
  }

  changeMode(): void {
    const modeToggle = this.$refs.modeToggle as HTMLElement;

    if (!modeToggle) {
      return;
    }

    modeToggle.style.transform = this.isPlatformMode ? 'translateX(125px)' : 'translateX(0px)';

    this.isPlatformMode = !this.isPlatformMode;
  }

  async created(): Promise<void> {
    await this.fetchProducts();
    this.freeProductPrices = await ProductService.fetchPrices(this.freeProduct.id);
    this.fullProductPrices = await ProductService.fetchPrices(this.fullProduct.id);
    this.pricesLoaded = true;
  }

  mounted() {
    setTimeout(() => {
      const freePlanContainerHeight = (this.$refs.free as HTMLDivElement[])[0]?.offsetHeight || 0;
      const fullPlanContainerHeight = (this.$refs.full as HTMLDivElement[])[0]?.offsetHeight || 0;
      this.biggerContainerHeight = Math.max(freePlanContainerHeight, fullPlanContainerHeight);
      this.$nextTick(() => this.isVisible = true);
    }, 1500);
  }

  get freeProduct(): IStripeProduct {
    const product = this.stripeProducts.find(({name}: IStripeProduct) => name.includes('Trial'))!;
    product.isFree = true;
    return product;
  }

  get fullProduct(): IStripeProduct {
    const product = this.stripeProducts.find(({name}: IStripeProduct) => !name.includes('Trial'))!;
    product.isFree = false;
    return product;
  }

  get freePrice(): number {
    const price = this.freeProductPrices
      .find(({currency}: IStripePrice) => currency === this.currentCurrency);

    return price ? price.amount : 7;
  }

  get fullPrice(): number {
    const price = this.fullProductPrices
      .find(({
               currency,
               nbAgentAccount
             }: IStripePrice) => currency === this.currentCurrency && nbAgentAccount === 1);

    return price ? price.amount : 0;
  }

  get currentFreePriceId(): string {
    const price = this.freeProductPrices
      .find(({currency}: IStripePrice) => currency === this.currentCurrency);

    return price ? price.id : '';
  }

  get currentFullPriceId(): string {
    const price = this.fullProductPrices
      .find(({
               currency,
               nbAgentAccount
             }: IStripePrice) => currency === this.currentCurrency
        && nbAgentAccount === this.countOfUsers);

    return price ? price.id : '';
  }

  get currentCurrency(): StripeCurrency {
    return this.isUSD ? StripeCurrency.USD : StripeCurrency.CAD;
  }

  get maxUserCount(): number {
    if (!this.fullProductPrices.length) {
      return 1;
    }

    return Math.max(...this.fullProductPrices
      .filter((p) => p.currency === this.currentCurrency)
      .map(p => p.nbAgentAccount));
  }

  async fetchProducts(): Promise<void> {
    await this.$store.dispatch(actionTypes.FETCH_STRIPE_PRODUCTS);
  }

  async loadPrices(productId: string, isFree: boolean): Promise<void> {
    await this.$store.dispatch(actionTypes.FETCH_STRIPE_PRICES, productId);
    await this.$router.push({
      path : '/sign-up',
      query: {
        productId,
        priceId : isFree ? this.currentFreePriceId : this.currentFullPriceId,
        freePlan: `${isFree}`
      }
    })
  }

  increaseCountOfUsers(): void {
    if(this.countOfUsers >= 3) return
    else this.countOfUsers ++;
  }

  decreaseCountOfUsers(): void {
    if (this.countOfUsers === 1) return
    else this.countOfUsers --;
  }

  toggleDemoPopup(value: boolean): void {
    this.isShowDemoPopup = value;
  }
}

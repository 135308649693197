




































import {Component, Vue} from 'vue-property-decorator';

@Component
export default class WelcomeBlock extends Vue {
  currentSlide: number = 0;

  get congratText(): string {
    if (this.currentSlide === 0) {
      return '"The best therapist has fur and four legs"'
    }

    return '"Once you get a dog, you can\'t imagine living without one"'
  }

  changeSlide(slideIdx: number): void {
    this.currentSlide = slideIdx;
  }
}

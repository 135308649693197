






























































































import { Component, Vue } from "vue-property-decorator";
import { Validate } from "vuelidate-property-decorators";
import { email, required } from "vuelidate/lib/validators";
import * as actionTypes from "@/store/action-types";
import { NotificationType } from "@/interfaces/models/INotification";
import { Getter } from "vuex-class";
import IAgentAccount, { AgentRole } from "@/interfaces/models/agent-account.interface";
import IBusinessAccount from "@/interfaces/models/business-account.interface";

@Component
export default class SignInForm extends Vue {
  @Getter agent!: IAgentAccount;
  @Getter currentBusinessAccount!: IBusinessAccount;

  @Validate({ required, email })
  email: string = "";
  @Validate({ required })
  password: string = "";

  isLoading: boolean = false;
  isShowPassword: boolean = false;

  get emailError(): string | null {
    if (!this.$v.email.$invalid || !this.$v.email.$dirty) {
      return null;
    }

    return !this.$v.email.required ? "Field is required" : "Bad email format";
  }

  get passwordFieldIcon(): string {
    return this.isShowPassword ? "mdi-eye-outline" : "mdi-eye-off-outline";
  }

  get passwordFieldType(): string {
    return this.isShowPassword ? "text" : "password";
  }

  mounted() {
    const message = this.$route.query.message;

    if (message) {
      this.$store.dispatch(actionTypes.SHOW_NOTIFICATION, {
        text: message,
        type: NotificationType.ERROR
      });
    }
  }

  togglePasswordShowing(): void {
    this.isShowPassword = !this.isShowPassword;
  }

  async signIn(): Promise<void> {
    this.$v.$touch();

    if (this.$v.$invalid) {
      return;
    }

    try {
      this.isLoading = true;
      await this.$store.dispatch(actionTypes.LOGIN, {
        email: this.email,
        password: this.password
      });

      await this.handleNextPageNavigation();
    } catch (e) {
      await this.$store.dispatch(actionTypes.SHOW_NOTIFICATION, {
        text: e.message,
        type: NotificationType.ERROR
      });
    } finally {
      this.isLoading = false;
    }
  }

  async handleNextPageNavigation(): Promise<void> {
    if (this.currentBusinessAccount.isExpired && this.agent.role === AgentRole.ADMINISTRATOR) {
      await this.$router.push("/settings/billing?tab=change-plan");
      if (this.currentBusinessAccount.expiredMessage) {
        await this.$store.dispatch(actionTypes.SHOW_NOTIFICATION, {
          text: this.currentBusinessAccount.expiredMessage,
          type: NotificationType.ERROR
        });
      }
    } else if (
      this.agent.businessAccount.isExpired &&
      this.agent.role !== AgentRole.ADMINISTRATOR
    ) {
      await this.$store.dispatch(actionTypes.SIGN_OUT);
      this.$store.dispatch(actionTypes.RESET);
      await this.$router.push("/plan-expire");
    } else {
      await this.$router.push("/inbox");
    }
  }
}

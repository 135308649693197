

































































































































































import {Component, Emit, Prop, Vue, Watch} from 'vue-property-decorator';
import {IStripeProduct} from "@/interfaces/models/IStripeProduct";
import {ProductService} from "@/http/services/product.service";
import {IStripePrice, StripeCurrency} from "@/interfaces/models/IStripePrice";
import {Getter} from "vuex-class";
import * as actionTypes from '@/store/action-types';
import {ITaxCalculation} from "@/interfaces/models/ITaxCalculation";
import {Helpers} from "@/common/utils/helpers.js";

@Component
export default class PlanPreview extends Vue {
  @Prop({type: Boolean, default: false}) paymentPage!: boolean;

  @Getter isPromoCodeValid!: boolean;
  @Getter stripeProducts!: IStripeProduct[];
  @Getter stripePrices!: IStripePrice[];

  countOfUsers: number = 1;
  isExpanded: number | null = null;

  currentProduct: IStripeProduct | null = null;
  currentPrice: IStripePrice | null = null;
  pricesForProduct: IStripePrice[] = [];
  isTrialProduct: boolean = false;
  currentCurrency: StripeCurrency = StripeCurrency.CAD;
  pricePerUser!: number;

  totalPrice: number = 0;
  taxes: ITaxCalculation | null = null;
  isInited: boolean = false;

  get taxesAmount(): number {
    if (!this.taxes) {
      return 0;
    }
    return this.taxes.taxes.reduce((acc, t) => acc + t.amount, 0);
  }

  formatPrice(price: number) {
    return Helpers.formatPrice(price, this.currentCurrency)
  }

  async mounted(): Promise<void> {
    const {productId, priceId} = this.$route.query as any;
    await this.fetchProduct(productId);
    await this.fetchPrice(productId, priceId);
    await this.fetchTotalPrice();

    this.$nextTick(() => this.isInited = true);
  }

  async fetchProduct(productId: string): Promise<void> {
    if (!this.stripeProducts.length) {
      await this.$store.dispatch(actionTypes.FETCH_STRIPE_PRODUCTS);
    }

    this.currentProduct = this.stripeProducts.find((product: IStripeProduct) => product.id === productId)!;
  }

  async fetchPrice(productId: string, priceId: string): Promise<void> {
    if (!this.stripePrices.length) {
      await this.$store.dispatch(actionTypes.FETCH_STRIPE_PRICES, productId);
    }

    this.currentPrice = this.stripePrices.find(price => price.id === priceId)!;
    this.isTrialProduct = this.currentPrice.nbAgentAccount === 0;
    this.currentCurrency = this.currentPrice.currency;
    this.pricePerUser = !this.isTrialProduct
      ? this.stripePrices
        .find(({
                 currency,
                 nbAgentAccount
               }: IStripePrice) => currency === this.currentCurrency && nbAgentAccount === 1)!.amount
      : 0;
    this.countOfUsers = this.currentPrice.nbAgentAccount;
  }

  get maxUserCount(): number {
    if (!this.stripePrices.length) {
      return 1;
    }

    return Math.max(...this.stripePrices
      .filter((p) => p.currency === this.currentCurrency)
      .map(p => p.nbAgentAccount));
  }

  async fetchTotalPrice(): Promise<void> {
    if (!this.paymentPage) {
      return;
    }

    this.taxes = await ProductService.fetchTaxes(
      +this.$route.query.provinceId,
      +this.$route.query.countryId,
      this.currentPrice!.id,
    );
    this.totalPrice = this.taxes.totalAmount;
  }

  @Watch('countOfUsers')
  handleCountOfUsersChange(): void {
    if (this.countOfUsers > this.maxUserCount) {
      return;
    }

    this.currentPrice = this.stripePrices
      .find(({
               nbAgentAccount,
               currency
             }: IStripePrice) => currency === this.currentCurrency && nbAgentAccount === this.countOfUsers)!;

    if (this.isInited) {
      this.$router.replace({
        query: {
          ...this.$route.query,
          priceId: this.currentPrice.id,
          productId: this.currentProduct!.id,
        }
      });
    }
  }

  @Emit('continue')
  isCanContinueSignUp(): boolean {
    return this.countOfUsers <= this.maxUserCount;
  }

  increaseCountOfUsers(): void {
    this.countOfUsers += 1;
    this.isCanContinueSignUp();
  }

  decreaseCountOfUsers(): void {
    if (this.countOfUsers === 1) {
      return;
    }

    this.countOfUsers -= 1;
    this.isCanContinueSignUp();
  }
}













import {Component, Vue} from "vue-property-decorator";
import WelcomeBlock from "@/components/auth/WelcomeBlock.vue";
import ChangePasswordForm from "@/components/auth/ChangePasswordForm.vue";
import * as actionTypes from '@/store/action-types';
import {NotificationType} from "@/interfaces/models/INotification";

@Component({
  components: {ChangePasswordForm, WelcomeBlock}
})
export default class ChangePassword extends Vue {
  created(): void {
    this.$store.dispatch(actionTypes.SHOW_NOTIFICATION, {
      text: this.$route.query.message,
      type: NotificationType.SUCCESS,
    })
  }
}

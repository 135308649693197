
































import {Component, Vue} from "vue-property-decorator";
import WelcomeBlock from "@/components/auth/WelcomeBlock.vue";
import DemoPopup from "@/components/demo/DemoPopup.vue";
import ConfirmPlanForm from "@/components/auth/ConfirmPlanForm.vue";
import SelectPlan from "@/components/auth/SelectPlan.vue";

@Component({
  components: {WelcomeBlock}
})
export default class ThanksForPayment extends Vue {

}

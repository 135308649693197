



















































import {Vue, Component} from 'vue-property-decorator';
import {Validate} from "vuelidate-property-decorators";
import {email, required} from "vuelidate/lib/validators";
import {AgentsService} from "@/http/services/agents.service";
import * as actionTypes from "@/store/action-types";
import {NotificationType} from "@/interfaces/models/INotification";

@Component
export default class RecoveryForm extends Vue {
  @Validate({required, email})
  email: string = '';

  isLoading: boolean = false;

  async resetPassword(): Promise<void> {
    this.$v.$touch();
    if (this.$v.$invalid) {
      return;
    }

    try {
      await AgentsService.passwordRecovery(this.email);
      await this.$router.push('/sign-in');
      await this.$store.dispatch(actionTypes.SHOW_NOTIFICATION, {
        text: 'Please check your email.',
        type: NotificationType.SUCCESS
      });
    } catch (e) {
       await this.$store.dispatch(actionTypes.SHOW_NOTIFICATION, {
        text: e.message,
        type: NotificationType.ERROR
      });
    } finally {
      this.isLoading = false;
    }
  }

  get emailError(): string | null {
    if (!this.$v.email.$invalid || !this.$v.email.$dirty) {
      return null;
    }

    return !this.$v.email.required ? 'Field is required' : 'Bad email format';
  }
}

import {ITimezone} from "@/interfaces/models/timezone.interface";
import API from "@/http/http-client";
import {ICountry, IProvince} from "@/interfaces/models/location.interface";

export const LocationService = {
  async fetchTimezones(): Promise<ITimezone[]> {
    const {data} = await API.get('/dog/timezones');
    return data;
  },

  async fetchCountries(): Promise<ICountry[]> {
    const {data} = await API.get('/dog/countries');
    return data;
  },

  async fetchProvinces(countryId: number): Promise<IProvince[]> {
    const {data} = await API.get('/dog/provinces', {
      params: {countryId}
    });
    return data;
  },
}

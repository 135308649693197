















import {Component, Vue} from "vue-property-decorator";
import WelcomeBlock from "@/components/auth/WelcomeBlock.vue";
import SignInForm from "@/components/auth/SignInForm.vue";

@Component({
  components: {SignInForm, WelcomeBlock}
})
export default class SignIn extends Vue {
  height: number = window.innerHeight - (this.isMobile ? 54 : 0);

  get isMobile(): boolean {
    return this.$vuetify.breakpoint.xs;
  }

  handleResize(): void {
    if (!this.isMobile) {
      return;
    }

    this.height = window.innerHeight - 54;
  }
}

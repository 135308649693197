





























































































































import {Component, Prop, Vue} from 'vue-property-decorator';
import {Validate} from "vuelidate-property-decorators";
import {email, required} from "vuelidate/lib/validators";
import * as actionTypes from "@/store/action-types";
import {LocationService} from "@/http/services/location.service";
import {ITimezone} from "@/interfaces/models/timezone.interface";
import {BusinessAccountService} from "@/http/services/business-account.service";
import {NotificationType} from "@/interfaces/models/INotification";
import {IStripeProduct} from "@/interfaces/models/IStripeProduct";
import {Getter} from "vuex-class";

@Component
export default class SignUpForm extends Vue {
  @Validate({required})
  companyName: string = '';
  @Validate({required, email})
  email: string = '';
  @Validate({required})
  firstName: string = '';
  @Validate({required})
  lastName: string = '';
  @Validate({required})
  phoneNumber: string = '';
  @Validate({required})
  timezone: number | null = null;

  @Prop({type: Boolean}) canContinue!: boolean;

  @Getter stripeProducts!: IStripeProduct[];

  currentProduct: IStripeProduct | null = null;
  timezones: ITimezone[] = [];
  isEmailDuplicate: boolean = false;
  isLoading: boolean = false;
  timeoutId: number = 0;
  isCheckingEmail: boolean = false;

 async mounted(): Promise<void> {
    this.loadTimezones();
    const {productId, priceId} = this.$route.query as any;
    await this.fetchProduct(productId);
  }

   async fetchProduct(productId: string): Promise<void> {
    if (!this.stripeProducts.length) {
      await this.$store.dispatch(actionTypes.FETCH_STRIPE_PRODUCTS);
    }

    this.currentProduct = this.stripeProducts.find((product: IStripeProduct) => product.id === productId)!;
  }

  handleDuplicateChecking(): void {
    clearTimeout(this.timeoutId);

    this.timeoutId = setTimeout(async () => {
      if (!this.isEmail(this.email)) {
        return;
      }

      this.isCheckingEmail = true;
      const {isDuplicate} = await BusinessAccountService.checkEmailDuplicate(this.email);
      this.isEmailDuplicate = isDuplicate;
      this.isCheckingEmail = false;
    }, 1000);
  }

  isEmail(email: string): boolean {
    const re = /\S+@\S+\.\S+/;
    return re.test(email);
  }

  async signUp(): Promise<void> {
    this.$v.$touch();

    if (this.$v.$invalid || !this.canContinue) {
      return;
    }

    try {
      this.isLoading = true;

      if (this.isEmailDuplicate) {
        return;
      }

      const createdAccount = await BusinessAccountService.registrationAttempt({
        companyName: this.companyName,
        email: this.email,
        firstName: this.firstName,
        lastName: this.lastName,
        phoneNumber: this.phoneNumber,
        timezoneId: this.timezone!,
        productId: this.$route.query.productId,
        priceId: this.$route.query.priceId,
      });
      // if(!this.currentProduct?.isFree){
      await this.$router.push({
        path: '/billing-info',
        query: {
          ...this.$route.query,
          firstName: this.firstName,
          lastName: this.lastName,
          email: this.email,
          phoneNumber: this.phoneNumber,
          companyName: this.companyName,
          timezoneId: `${this.timezone}`,
          businessAccountId: `${createdAccount.id}`,
        }
      });
      // } else {
      //   await this.$router.push({
      //   path: '/sign-in'
      // });
      // }
    } catch (e) {
       await this.$store.dispatch(actionTypes.SHOW_NOTIFICATION, {
        text: e.message,
        type: NotificationType.ERROR
      });
    } finally {
      this.isLoading = false;
    }
  }

  filterTimezones(timezone: ITimezone, value: string) {
    return `${timezone.zoneName} (GMT${timezone.offset / 3600})`.toLowerCase().includes(value.trim().toLowerCase());
  }

  async loadTimezones(): Promise<void> {
    try {
      this.timezones = await LocationService.fetchTimezones();
    } catch (e) {
       await this.$store.dispatch(actionTypes.SHOW_NOTIFICATION, {
        text: e.message,
        type: NotificationType.ERROR
      });
    }
  }
}


































































































































import {Component, Prop, Vue} from 'vue-property-decorator';
import {Validate} from "vuelidate-property-decorators";
import {required} from "vuelidate/lib/validators";
import {ICountry, IProvince} from "@/interfaces/models/location.interface";
import * as actionTypes from "@/store/action-types";
import {LocationService} from "@/http/services/location.service";
import {NotificationType} from "@/interfaces/models/INotification";

@Component
export default class BillingInfoForm extends Vue {
  @Validate({required})
  address: string = '';
  additionalAddress: string = '';
  @Validate({required})
  city: string = '';
  @Validate({required})
  province: number | null = null;
  @Validate({required})
  postCode: string = '';
  @Validate({required})
  country: number | null = null;

  @Prop({type: Boolean}) canContinue!: boolean;

  countries: ICountry[] = [];
  provinces: IProvince[] = [];
  isLoading: boolean = false;

  mounted(): void {
    this.loadCountries();
  }

  get emailError(): string | null {
    if (!this.$v.email.$invalid || !this.$v.email.$dirty) {
      return null;
    }

    return !this.$v.email.required ? 'Field is required' : 'Bad email format';
  }

  async createBusinessAccount(): Promise<void> {
    this.$v.$touch();

    if (this.$v.$invalid || !this.canContinue) {
      return;
    }

    try {
      this.isLoading = true;

      await this.$router.push({
        path: '/confirm-plan',
        query: {
          ...this.$route.query,
          address: this.address,
          additionalAddress: this.additionalAddress,
          city: this.city,
          postCode: this.postCode,
          provinceId: `${this.province}`,
          countryId: `${this.country}`
        }
      });
    } catch (e) {
       await this.$store.dispatch(actionTypes.SHOW_NOTIFICATION, {
        text: e.message,
        type: NotificationType.ERROR
      });
    } finally {
      this.isLoading = false;
    }
  }

  async loadCountries(): Promise<void> {
    if (this.countries.length) {
      return;
    }

    try {
      this.countries = await LocationService.fetchCountries();
    } catch (e) {
       await this.$store.dispatch(actionTypes.SHOW_NOTIFICATION, {
        text: e.message,
        type: NotificationType.ERROR
      });
    }
  }

  async loadProvinces(): Promise<void> {
    try {
      this.provinces = [];
      this.provinces = await LocationService.fetchProvinces(this.country!);
    } catch (e) {
       await this.$store.dispatch(actionTypes.SHOW_NOTIFICATION, {
        text: e.message,
        type: NotificationType.ERROR
      });
    }
  }
}

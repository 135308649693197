





















































































































































import {Vue, Component, Prop, Emit} from "vue-property-decorator";
import {Validate} from "vuelidate-property-decorators";
import {email, required} from "vuelidate/lib/validators";
import axios from 'axios';

@Component
export default class DemoPopup extends Vue {
  @Prop({type: Boolean, required: true}) showPopup!: boolean;

  @Validate({required, email})
  businessEmail: string = '';
  @Validate({required})
  companyName: string = '';
  @Validate({required})
  firstName: string = '';
  @Validate({required})
  lastName: string = '';
  @Validate({required})
  phoneNumber: string = '';
  @Validate({required})
  jobTitle: string = '';

  async scheduleDemo(): Promise<void> {
    this.$v.$touch();

    if (this.$v.$invalid) {
      return;
    }

    // const formData = new FormData();
    //
    // formData.append('inf_form_xid', '34cc3f0044d34b00511bb9c7bcd4d663');
    // formData.append('inf_form_name', 'Web Form submitted');
    // formData.append('infusionsoft_version', '1.70.0.281349');
    // formData.append('inf_field_FirstName', this.firstName);
    // formData.append('inf_field_LastName', this.lastName);
    // formData.append('inf_field_Email', this.businessEmail);
    // formData.append('inf_field_Phone1', `+1${this.phoneNumber}`);
    // formData.append('inf_field_Company', this.companyName);
    // formData.append('inf_field_JobTitle', this.jobTitle);
    //
    // try {
    //   const response = await axios({
    //     method: 'post',
    //     url: '/process/34cc3f0044d34b00511bb9c7bcd4d663',
    //     data: formData,
    //     headers: {'Content-Type': 'multipart/form-data' },
    //   });
    //   console.log(response);
    // } catch (e) {
    //   console.log(e);
    // }

    this.closePopup();
  }

  @Emit('close-popup')
  closePopup(): void {
  }

  get emailError(): string | null {
    if (!this.$v.businessEmail.$invalid || !this.$v.businessEmail.$dirty) {
      return null;
    }

    return !this.$v.businessEmail.required ? 'Field is required' : 'Bad email format';
  }
}














import {Component, Vue} from "vue-property-decorator";
import WelcomeBlock from "@/components/auth/WelcomeBlock.vue";
import DemoPopup from "@/components/demo/DemoPopup.vue";
import ConfirmPlanForm from "@/components/auth/ConfirmPlanForm.vue";
import PlanPreview from "@/components/auth/PlanPreview.vue";

@Component({
  components: {PlanPreview, ConfirmPlanForm, DemoPopup, WelcomeBlock}
})
export default class ConfirmPlan extends Vue {

}



































































































































































import { Component, Vue, Watch } from 'vue-property-decorator';
import { Validate } from "vuelidate-property-decorators";
import { maxValue, minValue, required } from "vuelidate/lib/validators";
import * as actionTypes from "@/store/action-types";
import Pusher from 'pusher-js';
import { ENV } from "@/common/utils/env";
import { BusinessAccountService } from "@/http/services/business-account.service";
import { NotificationType } from "@/interfaces/models/INotification";
import { CancelTokenSource } from "axios";
import { ProductService } from "@/http/services/product.service";
import { SET_PROMO_CODE_STATE } from "@/store/mutation-types";
import { ICreateBusinessAccount } from "@/interfaces/requests/ICreateBusinessAccount";

const validateCardNumber = (cardNumberFormatted: string) => {
  const cardNumber = cardNumberFormatted.trim().replace(/ /g, '')
  if (cardNumber.length < 16) {
    return false
  }

  let s = 0
  let doubleDigit = false
  for (let i = cardNumber.length - 1; i >= 0; i--) {
    let digit = +cardNumber[i]
    if (doubleDigit) {
      digit *= 2
      if (digit > 9) {
        digit -= 9
      }
    }
    s += digit
    doubleDigit = !doubleDigit
  }
  return s % 10 === 0
}

@Component
export default class ConfirmPlanForm extends Vue {
  @Validate({required, validateCardNumber})
  cardNumber: string = '';
  @Validate({required})
  cvv: string = '';
  @Validate({required})
  expDate: string = '';
  @Validate({required, minValue: minValue(1), maxValue: maxValue(12)})
  expMonth: string = '';
  @Validate({
    required, validYear(value: string) {
      return +value >= +new Date().getFullYear().toString().substr(-2)
    }
  })
  expYear: string = '';
  holderName: string = '';

  isLoading: boolean = false;
  isValidatingCard: boolean = false;
  timeoutId!: number;
  businessAccountId: number = 0;
  pusher: Pusher = new Pusher(ENV.PUSHER_APP_KEY, {
    cluster: 'us2'
  });

  promoCode: string = '';
  isPromoCodeValid: boolean = false;
  timeOutId: number = 0;
  cancelToken: CancelTokenSource | null = null;
  isValidatingPromoCode: boolean = false;

  mounted() {
    const channel = this.pusher.subscribe('textodog-payment');

    channel.bind('paymentStatusUpdated', (data: { businessAccountId: number, isSuccess: boolean }) => {
      if (data.isSuccess) {
        this.$router.push('/thanks');
      } else {
        this.$store.dispatch(actionTypes.SHOW_NOTIFICATION, {
          text: 'Oops... Something went wrong!',
          type: NotificationType.ERROR
        });
      }
    });
  }

  get isFreePlan(): boolean {
    return this.$route.query.freePlan === 'true';
  }

  beforeDestroy(): void {
    this.pusher.unsubscribe('textodog-payment');
  }

  @Watch('expDate')
  expDateWatcher() {
    this.expMonth = this.expDate.split('/')[0];
    this.expYear = this.expDate.split('/')[1];
  }

  validatePromoCode(): void {
    clearTimeout(this.timeOutId);
    this.cancelToken?.cancel();
    if (this.promoCode) {
      this.isValidatingPromoCode = true;
      this.timeOutId = setTimeout(async () => {
        const {cancelToken, response} = ProductService.validatePromoCode(this.promoCode);
        this.cancelToken = cancelToken;
        try {
          const {data} = await response;
          this.isPromoCodeValid = data.isValid;
          this.$store.commit(SET_PROMO_CODE_STATE, this.isPromoCodeValid);
        } catch (e) {
          console.log(e);
        } finally {
          this.isValidatingPromoCode = false;
        }
      }, 1000);
    } else {
      this.isPromoCodeValid = false;
    }
  }

  getAccountCreationData(): ICreateBusinessAccount {
    const data: ICreateBusinessAccount = {
      companyName      : this.$route.query.companyName as string,
      firstName        : this.$route.query.firstName as string,
      lastName         : this.$route.query.lastName as string,
      phoneNumber      : this.$route.query.phoneNumber as string,
      priceId          : this.$route.query.priceId as string,
      productId        : this.$route.query.productId as string,
      provinceId       : +this.$route.query.provinceId,
      countryId        : +this.$route.query.countryId,
      timezoneId       : +this.$route.query.timezoneId,
      postCode         : this.$route.query.postCode as string,
      address          : this.$route.query.address as string,
      additionalAddress: (this.$route.query.additionalAddress as string).trim().length
        ? this.$route.query.additionalAddress as string : null,
      city             : this.$route.query.city as string,
      email            : this.$route.query.email as string,
      cardNumber       : this.cardNumber.replace(/ /g, ''),
      cvc              : +this.cvv,
      expireMonth      : +this.expDate.split('/')[0],
      expireYear       : +this.expDate.split('/')[1],
    };

    if (this.isFreePlan && this.isPromoCodeValid) {
      data.promoCode = this.promoCode;
    }

    return data;
  }

  async makePayment(): Promise<void> {
    this.$v.$touch();
    if (!this.isPromoCodeValid && this.$v.$invalid) {
      return;
    }

    try {
      this.isLoading = true;
      const createdBusinessAccount = await BusinessAccountService.createBusinessAccount(this.getAccountCreationData());
      this.businessAccountId = createdBusinessAccount.id;
    } catch (e) {
      this.isLoading = false;
      await this.$store.dispatch(actionTypes.SHOW_NOTIFICATION, {
        text: e.message,
        type: NotificationType.ERROR
      });
    } finally {
      this.isLoading = false;
    }
  }
}














import {Component, Vue} from "vue-property-decorator";
import WelcomeBlock from "@/components/auth/WelcomeBlock.vue";
import SignUpForm from "@/components/auth/SignUpForm.vue";
import PlanPreview from "@/components/auth/PlanPreview.vue";

@Component({
  components: {PlanPreview, SignUpForm, WelcomeBlock}
})
export default class SignUp extends Vue {
  isCanContinue: boolean = true;

  mounted(): void {
    scrollTo(0, 0);
  }

  handleContinueStateChange(value: boolean): void {
    this.isCanContinue = value;
  }
}
